import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./paymentPages.css";
import sucessImg from "../../assets/images/Animation - 1702299415745.gif";
import CartService from "../../services/cart.service";

const PaymentSuccess = () => {
  const [showSucess, setShowSucess] = useState(false);

  const handleClose = () => {
    setShowSucess(false);
    window.location.href = "https://exceltest.aeontechhub.com/";
  };

  const handleShow = (e) => {
    setShowSucess(true);
  };

  useEffect(() => {
    handleShow();

    CartService.getPaymentStatus()
      .then((resp) => {})
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <div className="success">
        {/* <button onClick={handleShow}>Show</button> */}
        <Modal show={showSucess} onHide={handleClose} centered>
          <Modal.Body>
            <div className="sucess-modal">
              <img src={sucessImg} alt="sucess" />
              <div className="payment-success">
                <span>Payment Successful!</span>
                <div className="payent-details">
                  <h2>BHD 3,400</h2>
                  <h5>Transaction ID: 10001000</h5>
                  <h5>Date: 11/12/2023</h5>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PaymentSuccess;
