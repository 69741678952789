import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import "./Onboarding.css";
import "./Responsive.css";
import OrganisationService from "../../../services/organisation.service";
import AuthService from "../../../services/auth.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Organization = ({ nextStep }) => {
  const [validated, setValidated] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [stepSts, setStepSts] = useState(false);
  const [logoImg, setLogoImg] = useState("");

  const [chnageData, setChangeData] = useState(false);

  const [orgData, setOrgData] = useState({
    user_id: "",
    orgname: "",
    industry: "",
    logo: "",
  });

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setOrgData({ ...orgData, user_id: user.user });

    OrganisationService.getOrg(user.user)
      .then((response) => {
        setOrgData({
          user_id: user.user,
          orgname: response.data[0].organization_name,
          industry: response.data[0].Industry,
          logo: response.data[0].logo,
        });
        setUploaded(false);
        setStepSts(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrgData({ ...orgData, [name]: value });
    setChangeData(true);
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setOrgData({ ...orgData, [name]: e.target.files[0] });
    setLogoImg(URL.createObjectURL(e.target.files[0]));
    setUploaded(true);
    setChangeData(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);

      toast.warn("Please fill all required fields.!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    setValidated(true);
    event.preventDefault();

    const formData = new FormData();

    formData.append("usp", orgData.user_id);
    formData.append("orgname", orgData.orgname);
    formData.append("industry", orgData.industry);
    formData.append("logo", orgData.logo);

    if (chnageData) {
      OrganisationService.createOrg(formData)
        .then((response) => {
          setOrgData({
            user_id: response.data.user_id,
            orgname: response.data.organization_name,
            industry: response.data.Industry,
            logo: response.data.logo,
          });

          toast.success("Organisation Added Successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setStepSts(true);

          setTimeout(() => {
            nextStep();
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      nextStep();
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <ToastContainer />
        <Form.Group controlId="formBasicOrgName">
          <Form.Label>Organization Name *</Form.Label>
          <div className="input-box">
            <Form.Control
              type="text"
              placeholder="Begin Typing...."
              name="orgname"
              onChange={handleInputChange}
              value={orgData.orgname}
              required
            />
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 20.199 20.201"
              >
                <g
                  id="Group_11227"
                  data-name="Group 11227"
                  transform="translate(-2402.326 -6200.833)"
                >
                  <path
                    id="Path_11890"
                    data-name="Path 11890"
                    d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                    transform="translate(752.656 5510.435)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path
                    id="Path_11892"
                    data-name="Path 11892"
                    d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                    transform="translate(751.221 5511.583)"
                  />
                </g>
              </svg>
            </span>
          </div>
        </Form.Group>
        <Form.Group controlId="formBasicIndustry">
          <Form.Label>Industry *</Form.Label>
          <div className="input-box">
            <Form.Control
              type="text"
              placeholder="Begin Typing...."
              name="industry"
              onChange={handleInputChange}
              value={orgData.industry}
              required
            />
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 20.199 20.201"
              >
                <g
                  id="Group_11227"
                  data-name="Group 11227"
                  transform="translate(-2402.326 -6200.833)"
                >
                  <path
                    id="Path_11890"
                    data-name="Path 11890"
                    d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                    transform="translate(752.656 5510.435)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path
                    id="Path_11892"
                    data-name="Path 11892"
                    d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                    transform="translate(751.221 5511.583)"
                  />
                </g>
              </svg>
            </span>
          </div>
        </Form.Group>
        <Form.Group controlId="formBasicLogo">
          <Form.Label>Add your Organization Logo</Form.Label>
          <div className="upload-flex">
            <div className="upload-area">
              <input
                type="file"
                name="logo"
                id="logo"
                onChange={handleFileChange}
              />
              <button className="upload-btn">
                {uploaded === true ? (
                  <>
                    <img
                      src={logoImg}
                      className="previewImg"
                      width="100px"
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    <>
                      <i className="uil uil-upload"></i> Drop Here
                    </>
                  </>
                )}
              </button>
            </div>
            <div className="img-area">
              {orgData.logo !== "" ? (
                <>
                  <img
                    src={"https://exceltest.aeontechhub.com/lms/" + orgData.logo}
                    className="previewImg"
                    width="100px"
                    alt=""
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Form.Group>
        <div className="flex-end">
          {/* <input
            type="button"
            className="onboard-btn-o"
            value="Skip"
            onClick={nextStep}
          /> */}
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>
    </>
  );
};
export default Organization;
