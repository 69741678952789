import React, { useRef, useEffect } from "react";
import Iframe from "react-iframe";
import { useState } from "react";
import CourseService from "../services/course.service";
import AuthService from "../services/auth.service";
import LoadingGif from "../../src/assets/images/Loading.gif";
import ReportService from "../services/report.service";

const Player = ({
  aid,
  cid,
  getCourseActivities,
  getCourseCompSts,
  activitySts,
  attemptCount,
  attempt,
  fetchActivityResults,
  file_name,
  activity_type,
  comp_sts,
  activity_name,
  enrolid,
}) => {
  console.log("player enrollid", enrolid)
  const iframeRef = useRef(null);
  const [isFunctionRunning, setIsFunctionRunning] = useState(false);
  const videoRef = useRef(null);
  const [duration, setDuration] = useState(0);
  // const [currentTime, setCurrentTime] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [mediaStatus, setMediaStatus] = useState("");
  const [activityResult, setActivityResult] = useState({
    strscore_max: "",
    strscore_min: "",
    strscore_raw: "",
    strsession_time: "",
    strlesson_status: "",
    course_id: cid,
    activity_id: aid,
    activity_typeid: "",
    student_id: "",
    enrollid: enrolid,
  });

  // const [isSafari, setIsSafari] = useState(
  //   navigator.appVersion.indexOf("Safari") >= 0
  // );
  // const [isChromeless, setIsChromeless] = useState(false);
  // const [isResizeable, setIsResizeable] = useState(false);
  // const [contentWidth, setContentWidth] = useState(1300);
  // const [contentHeight, setContentHeight] = useState(791);

  const isSafari = navigator.appVersion.indexOf("Safari") >= 0;
  const isChromeless = false;
  const isResizeable = false;
  const contentWidth = 1300;
  const contentHeight = 791;

  const [startPage, setStartPage] = useState(
    "https://exceltest.aeontechhub.com/lms/" + file_name + "/index_lms.html"
  );
  const browserSize = "optimal";

  const launchContent = () => {
    let width = window.screen.availWidth;
    let height = window.screen.availHeight;

    if (
      width > contentWidth &&
      height > contentHeight &&
      browserSize !== "fullscreen"
    ) {
      width = contentWidth;
      height = contentHeight;

      if (!isSafari && !isChromeless) {
        width += 17;
      }
    }

    let options = `width=${width},height=${height}`;
    options += isResizeable ? ",resizable=yes" : ",resizable=no";

    if (isChromeless) {
      options += ",status=0,toolbar=0,location=0,menubar=0,scrollbars=0";
    } else {
      options += ",status=1,toolbar=1,location=1,menubar=1,scrollbars=1";
    }

    if (isSafari) {
      const newWindow = window.open("", "_blank", options);
      newWindow.location = startPage;
      window.onfocus = function () {
        // console.log("Window is focused!");
        console.log("Please do not open new tab while running course.");
        newWindow.close();
      };
    } else {
      const newWindow = window.open(startPage, "_blank", options);
      const script = newWindow.document.createElement("script");
      script.innerHTML = `
      document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      });
    `;
      newWindow.document.head.appendChild(script);
    }
  };

  const scromRun = (cid, aid, user, enrolid) => {
    let activityRes = {};

    setActivityResult((prevState) => ({
      ...prevState,
      enrollid: enrolid,
    }));

    const tspInit = (window, storage, prefix, callback) => {
      const data = {
        csid: cid,
        actid: aid,
      };

      CourseService.launchCourse(data)
        .then((resp) => {})
        .catch((e) => console.log(e));

      prefix = typeof prefix !== "undefined" ? prefix : "";
      callback = typeof callback !== "undefined" ? callback : console.log;

      window.API = {};

      window.scormStatus = {
        lesson_status: "",
        score_raw: 0,
        score_max: 100,
        score_min: 0,
        session_time: 0,
        detailed_answers: {},
        lesson_location: "",
        student_id: user.user,
        course_id: cid,
        enrollid: enrolid
      };

      window.API.LMSInitialize = function () {
        console.log("LMSInitialize");
      };
      window.API.LMSTerminate = function () {
        console.log("LMSTerminate");
      };

      window.API.LMSGetValue = function (varname) {
        varname = prefix + varname;
        var ret = storage.getItem(varname);
        if (
          ret == null &&
          varname.indexOf("_count", this.length - "_count".length) !== -1
        ) {
          ret = 0;
          storage.setItem(varname, ret);
        }

        return ret;
      };

      window.API.LMSSetValue = function (varname, varvalue) {
        varname = prefix + varname;

        var m = varname.match(/([0-9]+)\.cmi\.interactions\.([0-9]+)\.id/);
        if (m != null) {
          storage.setItem(
            "{{scorm.id}}.cmi.interactions._count",
            parseInt(m[2]) + 1
          );
        }

        m = varname.match(/([0-9]+)\.cmi\.interactions\.([0-9]+)\.result/);
        if (m != null) {
          var key = storage.getItem(
            prefix + "cmi.interactions." + parseInt(m[2]) + ".id"
          );
          window.scormStatus.detailed_answers[key] = varvalue;
        }

        if (varname === prefix + "cmi.core.lesson_status")
          window.scormStatus.lesson_status = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.scormStatus.lesson_status,
        });
        if (varname === prefix + "cmi.core.score.raw")
          window.scormStatus.score_raw = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_raw: window.scormStatus.score_raw,
        });
        if (varname === prefix + "cmi.core.score.max")
          window.scormStatus.score_max = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_max: window.scormStatus.score_max,
        });
        if (varname === prefix + "cmi.core.score.min")
          window.scormStatus.score_min = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_min: window.scormStatus.score_min,
        });
        if (varname === prefix + "cmi.core.session_time")
          window.scormStatus.session_time = varvalue;
        setActivityResult({
          ...activityResult,
          strsession_time: window.scormStatus.session_time,
        });

        if (varname === prefix + "cmi.core.lesson_location")
          window.scormStatus.lesson_location = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.scormStatus.lesson_status,
        });

        if (varname === prefix + "cmi.core.student_id") {
          window.scormStatus.student_id = varvalue;
          setActivityResult({
            ...activityResult,
            student_id: window.scormStatus.student_id,
          });
        }

        if (varname === prefix + "cmi.core.course_id") {
          window.scormStatus.course_id = varvalue;
          setActivityResult({
            ...activityResult,
            student_id: window.scormStatus.course_id,
          });
        }

        storage.setItem(varname, varvalue);
      };

      window.API.LMSCommit = function () {
        // console.log("LMSCommit");
        callback(window.scormStatus);
        return true;
      };

      window.API.LMSFinish = function () {
        // console.log("LMSFinish");
        const keys = Object.keys(localStorage);
        const newData = {};
        keys.forEach((key) => {
          if (key.startsWith(`CORS.${cid}.ACT.${aid}.UID.${user.user}.`)) {
            const storedValue = localStorage.getItem(key);
            newData[key] = storedValue;
          }
        });

        const scromdata = {
          enrollid: enrolid,
          userid: user.user,
          csid: cid,
          activity_id: aid,
          scrdata: newData,
        };

        ReportService.uploadScromData(scromdata)
          .catch((resp) => {
            console.log(resp.data);
          })
          .catch((e) => console.log(e));

        uploadResult(activityRes);
      };

      window.API.LMSGetLastError = function () {};
      window.API.LMSGetErrorString = function () {};
      window.API.LMSGetDiagnostic = function () {};
    };

    const supports_html5_storage = () => {
      try {
        return "localStorage" in window && window["localStorage"] !== null;
      } catch (e) {
        return false;
      }
    };

    if (!supports_html5_storage()) {
      window.localStorage = {};
    }

    tspInit(
      window,
      window.localStorage,
      `CORS.${cid}.ACT.${aid}.UID.${user.user}.`,
      function (progress) {
        var progressString = JSON.stringify(progress);
        localStorage.setItem("std_progress", progressString);
        activityRes = {
          strscore_max: progress.score_max,
          strscore_min: progress.score_min,
          strscore_raw: progress.score_raw,
          strsession_time: progress.session_time,
          strlesson_status: progress.lesson_status,
          course_id: cid,
          activity_id: aid,
          student_id: user.user,
          activity_typeid: "1",
          enrollid: enrolid,
        };
      }
    );
  };

  const handlePlay = () => {
    const video = videoRef.current;
    if (video) {
      let durationInMinutes = Math.floor(video.duration / 60);
      // let durationInSeconds = durationInMinutes % 60;
      setDuration(Math.floor(video.duration));
      const intervalId = setInterval(() => {
        // const unit = "seconds";
        // setCurrentTime(Math.round(video.currentTime) + " " + unit);
        setTotalPlayTime((prevTotalPlayTime) => prevTotalPlayTime + 1);
      }, 1000);

      video.onpause = video.onended = () => {
        clearInterval(intervalId);
      };
    }
  };

  const handlePause = () => {
    uploadMediaRes();
  };

  const uploadResult = (data) => {
    const user = AuthService.getCurrentUser();

    console.log(data);

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    console.log("datanew", datanew);
    console.log("fetchResdata", fetchResdata);

    CourseService.uploadScromResult(data)
      .then((res) => {
        if (res.data !== false) {
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => console.log(e));
  };

  const uploadMediaRes = () => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    const mediadata = {
      strscore_max: "",
      strscore_min: "",
      strscore_raw: "",
      strsession_time: totalPlayTime,
      strlesson_status: mediaStatus,
      course_id: cid,
      activity_id: aid,
      activity_typeid: "2",
      duration: duration,
      student_id: user.user,
    };

    CourseService.uploadScromResult(mediadata)
      .then((res) => {
        if (res.data !== false) {
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleIframeLoad = () => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    const mediadata = {
      strscore_max: "",
      strscore_min: "",
      strscore_raw: "",
      strsession_time: "",
      strlesson_status: "completed",
      course_id: cid,
      activity_id: aid,
      activity_typeid: "2",
      duration: duration,
      student_id: user.user,
    };

    CourseService.uploadScromResult(mediadata)
      .then((res) => {
        if (res.data !== false) {
          setIsFunctionRunning(true);
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsFunctionRunning(false);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setActivityResult({ ...activityResult, student_id: user.user });
    scromRun(cid, aid, user, enrolid);

    if (totalPlayTime >= duration) {
      setMediaStatus("completed");
    } else {
      setMediaStatus("incomplete");
    }

    const disableScreenshot = (e) => {
      navigator.clipboard.writeText("");
      console.log("screenshot disabled");
    };

    document.addEventListener("keyup", disableScreenshot);

    return () => {
      document.removeEventListener("keyup", disableScreenshot);
    };
  }, [aid]);

  return (
    <>
      {attempt !== "0" && attemptCount >= attempt ? (
        <>
          <div className="loadinggif-flex">
            <img src={LoadingGif} className="loadinggif" alt="" />
            <span>You have reached the maximum number of attempts.</span>
          </div>
        </>
      ) : (
        <>
          {file_name.includes("mp4") ? (
            <>
              <video
                ref={videoRef}
                id="myvideo"
                width="100%"
                onPlay={handlePlay}
                onPause={handlePause}
                controls
                controlsList="nodownload"
                onContextMenu={() => false}
              >
                <source
                  src={"https://exceltest.aeontechhub.com/lms/" + file_name}
                  type="video/mp4"
                />
              </video>
            </>
          ) : activity_type === "2" && file_name.includes(".pdf") ? (
            <>
              <Iframe
                url={"https://exceltest.aeontechhub.com/lms/" + file_name}
                id="pdfPlayer"
                width="640px"
                height="320px"
                display="block"
                position="relative"
                ref={iframeRef}
                className="mb-3"
              />

              {isFunctionRunning &&
              activitySts &&
              activitySts === "Completed" ? (
                <></>
              ) : (
                <>
                  <button className="mark-comp" onClick={handleIframeLoad}>
                    Mark as Completed
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <h4>{activity_name}</h4>
              {comp_sts === 0 ? (
                <>
                  {attemptCount >= 1 ? (
                    <>
                      <button
                        onClick={() => {
                          launchContent();
                          setStartPage(
                            "https://exceltest.aeontechhub.com/lms/" +
                              file_name +
                              "/index_lms.html"
                          );
                        }}
                        className="launch"
                      >
                        Resume
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          launchContent();
                          setStartPage(
                            "https://exceltest.aeontechhub.com/lms/" +
                              file_name +
                              "/index_lms.html"
                          );
                        }}
                        className="launch"
                      >
                        Launch
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      launchContent();
                      setStartPage(
                        "https://exceltest.aeontechhub.com/lms/" +
                          file_name +
                          "/index_lms.html"
                      );
                    }}
                    className="launch"
                  >
                    You have successfully completed this activity. You can
                    restart this activity or start next activity.
                  </button>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default Player;
