import React from "react";

const Invoice = ({ invoiceDetails, otherInvDetails, invoiceRef }) => {
  return (
    <>
      <div className="invoice-bg">
        <div ref={invoiceRef} className="invoice">
          <div className="header">
            <div className="img">
              <img src="https://exceltest.aeontechhub.com/lms/uploads/images/aeon-me.png" width="125px" alt="" />
            </div>
            <div className="middle">
              <h3>Invoice</h3>
              <h4>aeon software me wll</h4>
              <p>
                Building No. 743, Flat 204, Road 0831, Sanabis 0408, Bahrain.
              </p>
              <p>Commercial Registration (CR) No: 145015-1</p>
            </div>
          </div>
          <div className="billing-info">
            <div className="left">
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                Bill to,
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "5px",
                }}
              >
                Gulf Aviation Academy BSC
              </p>
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: "14px",
                  marginBottom: "5px",
                }}
              >
                B.S.C. P.O. BOX 23773, Muharraq Kingdom of Bahrain
              </p>
              <p style={{ fontWeight: "normal", fontSize: "14px" }}>
                Kingdom of Bahrain.
              </p>
            </div>
            <div className="right">
              <p style={{ fontSize: "14px" }}>
                Invoice: {otherInvDetails.invoiceNo}
              </p>
              <p style={{ fontSize: "14px" }}>
                Date: {otherInvDetails.invoiceDate}
              </p>
            </div>
          </div>
          <div className="course-details">
            <table cellPadding="0" cellSpacing="0">
              <thead>
                <tr className="border-column">
                  <th className="wh-25">Sr.No.</th>
                  <th>Description</th>
                  <th className="w-same">QTY</th>
                  <th className="w-same">Rate</th>
                  <th className="w-same">Amount In BHD</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-column">
                  <td></td>
                  <td style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Ref : Agreement dated. 20th February 2021.
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr className="border-column">
                  <td></td>
                  <td style={{ fontWeight: "bold", fontSize: "16px" }}>
                    As Per Excel Attached
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                {Array.isArray(invoiceDetails)
                  ? invoiceDetails.map((invoiceDetail, index) => {
                      return (
                        <>
                          <tr className="border-column">
                            <td>{index + 1}.</td>
                            <td
                              style={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                              Invoice for {invoiceDetail.course_name} (
                              {invoiceDetail.qty} Users @ {invoiceDetail.rate})
                            </td>
                            <td
                              style={{ textAlign: "center", fontSize: "14px" }}
                            >
                              {invoiceDetail.qty}
                            </td>
                            <td
                              style={{ textAlign: "center", fontSize: "14px" }}
                            >
                              {invoiceDetail.rate}
                            </td>
                            <td className="align-right">
                              {invoiceDetail.amountcal}
                            </td>
                          </tr>
                          {/* <tr className="border-column">
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr> */}
                        </>
                      );
                    })
                  : null}

                <tr className="border-top">
                  <td
                    style={{
                      verticalAlign: "top",
                      fontSize: "12px",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                    colSpan="2"
                    rowspan="3"
                  >
                    Amount in words : {otherInvDetails.totalAmtWords} Only.
                  </td>
                  <td
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      textAlign: "right",
                      paddingRight: "5px",
                    }}
                  >
                    Total
                  </td>
                  <td></td>
                  <td
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                    className="align-right"
                  >
                    {otherInvDetails.totalAmt}
                  </td>
                </tr>
                <tr className="border-top">
                  <td
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      textAlign: "right",
                      paddingRight: "5px",
                    }}
                  >
                    Vat Amount
                  </td>
                  <td></td>
                  <td className="align-right">NA</td>
                </tr>
                <tr className="border-top">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className="border-top">
                  <td colSpan="2">Payment Terms : Immediate</td>
                  <td
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      textAlign: "right",
                      paddingRight: "5px",
                    }}
                  >
                    Total Bill Amount
                  </td>
                  <td></td>
                  <td
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                    className="align-right"
                  >
                    {otherInvDetails.totalAmt}
                  </td>
                </tr>
                <tr className="border-top">
                  <td colSpan="2">&nbsp;</td>
                  <td colSpan="3">&nbsp;</td>
                </tr>
                <tr className="border-top">
                  <td colSpan="2">
                    <div className="account-info">
                      <p style={{ fontWeight: "bold" }}>AEON SOFTWARE ME WLL</p>
                      <p>Account No: 100001008888</p>
                      <p>IBAN NO : BH03BIBB100001008888</p>
                      <p>Swift Code: BIBBBHBM</p>
                    </div>
                  </td>
                  <td colSpan="3">
                    <div className="declaration">
                      <p>Declaration</p>
                      <p>
                        We declare that this invoice show the actual price of
                        the
                      </p>
                      <p>Goods/service provided and that all particulars are</p>
                      <p>true and correct.</p>
                    </div>
                  </td>
                </tr>
                <tr className="border-right">
                  <td colSpan="2">&nbsp;</td>
                  <td colSpan="3">&nbsp;</td>
                </tr>
                <tr className="border-right">
                  <td colSpan="2">&nbsp;</td>
                  <td colSpan="3">&nbsp;</td>
                </tr>
                <tr className="border-top">
                  <td colSpan="2">
                    <div className="receiver-sign">
                      <p></p>
                      <p>Receiver's Signature & Stamp</p>
                    </div>
                  </td>
                  <td colSpan="3">
                    <div className="receiver-sign">
                      <p>FOR AEON SOFTWARE ME WLL</p>
                      <img
                        src="https://exceltest.aeontechhub.com/lms/uploads/images/account-sign.png"
                        className="invoicesign"
                        width="175px"
                        alt=""
                      />
                      {/* <p>Authorized Signatory</p> */}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
