import React, { useState, useEffect } from "react";
import ActionFlex from "./ActionFlex";
import "./accounts.css";
import { Link } from "react-router-dom";
import "../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import { UilInfoCircle } from "@iconscout/react-unicons";
import { UilEye } from "@iconscout/react-unicons";
import InvoiceService from "../../services/invoice.service";

function PaidInvoiceList({ roleid }) {
  const [invoiceLists, setInvoiceLists] = useState([]);
  const [showComment, setShowComment] = useState(false);

  useEffect(() => {
    getInvoiceList();
  }, []);

  const getInvoiceList = () => {
    InvoiceService.getInvoiceList()
      .then((resp) => {
        const approvedList = resp.data.filter(
          (item) => item.invoice.statusflag === "4"
        );
        // console.log(approvedList);
        setInvoiceLists(approvedList);
        loadDatatable();
      })
      .catch((e) => console.log(e));
  };

  const loadDatatable = () => {
    if (!$.fn.DataTable.isDataTable("#allLearners")) {
      function filterGlobal() {
        $("#allLearners")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#allLearners")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#allLearners").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
        }, 0);
      });
    }
  };

  const getColorForOption = (optionValue) => {
    switch (optionValue) {
      case "inProcess":
        return "#7DC9FC";
      case "comment":
        return "#E2C13B";
      case "approved":
        return "#39B91A";
      default:
        return "white";
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex />
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                  />
                </div>
                <div className="admin-users">
                  <div className="table-responsive">
                    <table className="table" id="allLearners">
                      <thead>
                        <tr>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Duration</th>
                          <th>Course Enrollment List</th>
                          <th>Invoice Date</th>
                          <th>Invoice No.</th>
                          <th>Invoice</th>
                          <th>Payment Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(invoiceLists)
                          ? invoiceLists.map((invoiceList) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <span>
                                        {invoiceList.invoice.datefrom}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{invoiceList.invoice.dateto}</span>
                                    </td>
                                    <td>
                                      <span>15 Days</span>
                                    </td>
                                    <td>
                                      <Link
                                        to="/StudentList"
                                        className="icon"
                                        state={{
                                          isPaid: true,
                                          fdate: invoiceList.invoice.datefrom,
                                          tdate: invoiceList.invoice.dateto,
                                          invid: invoiceList.invoice.invoice_id,
                                          invno: invoiceList.invoice.invoice_no,
                                        }}
                                      >
                                        <UilEye size="24" />
                                      </Link>
                                    </td>
                                    <td>
                                      <span>{invoiceList.invoice.cdate}</span>
                                    </td>
                                    {roleid === "6" ? (
                                      <>
                                        <td>
                                          <span>
                                            {invoiceList.invoice.invoice_no}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="exl-down">
                                            <Link
                                              to="/Invoice"
                                              state={{
                                                isPaid: true,
                                                invno:
                                                  invoiceList.invoice
                                                    .invoice_no,
                                              }}
                                            >
                                              <svg
                                                id="excel-file"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18.889"
                                                height="18.886"
                                                viewBox="0 0 18.889 18.886"
                                              >
                                                <path
                                                  id="Path_12182"
                                                  data-name="Path 12182"
                                                  d="M5.423-510.852C2.45-510.277.015-509.8.011-509.8S0-506.5,0-502.459v7.342l.081.018c.048.011,2.461.476,5.361,1.033s5.313,1.022,5.361,1.033l.081.018v-9.445c0-5.195-.011-9.445-.026-9.441S8.4-511.428,5.423-510.852Zm-.616,5.885c.3.627.579,1.236.62,1.354s.081.24.092.269.077-.1.162-.314.387-.834.694-1.4l.557-1.033.926-.011.93-.007-.074.118c-.627,1.029-2.084,3.453-2.1,3.486s1.819,3.084,2.2,3.656l.055.081-.992-.007-.989-.011-.657-1.236a13.05,13.05,0,0,1-.7-1.431c-.026-.1-.052-.185-.063-.177s-.052.125-.1.255-.369.771-.719,1.424l-.635,1.184H3.04c-.535,0-.974-.011-.974-.022s1.033-1.645,2.2-3.468l.122-.188-.978-1.675-1.07-1.834-.092-.155H4.265Z"
                                                  transform="translate(0 511.9)"
                                                  // fill="#fff"
                                                />
                                                <path
                                                  id="Path_12183"
                                                  data-name="Path 12183"
                                                  d="M315-451.912v1.088h2.214v1.476H315v1.439h2.177v1.439H315v1.476h2.177v1.439H315v1.439h2.177v1.476H315v2.177h3.376a32.513,32.513,0,0,0,3.5-.052.726.726,0,0,0,.354-.365c.03-.074.041-2.177.041-6.855,0-5.648-.007-6.77-.052-6.87a.726.726,0,0,0-.365-.354c-.07-.03-1.184-.041-3.479-.041H315Zm5.822,1.815-.011.73-1.446.011-1.45.007v-1.476h2.915Zm.007,2.907v.719h-2.915v-1.439h2.915Zm0,2.915v.719h-2.915v-1.439h2.915Zm-.007,2.9.007.73h-2.915v-1.476l1.45.007,1.446.011Z"
                                                  transform="translate(-303.379 455.173)"
                                                  // fill="#fff"
                                                />
                                              </svg>
                                            </Link>
                                          </span>
                                        </td>
                                      </>
                                    ) : invoiceList.invoice.statusflag ===
                                      "4" ? (
                                      <>
                                        <td>
                                          <span>
                                            {invoiceList.invoice.invoice_no}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="exl-down">
                                            <Link
                                              to="/Invoice"
                                              state={{
                                                isPaid: true,
                                                invno:
                                                  invoiceList.invoice
                                                    .invoice_no,
                                              }}
                                            >
                                              <svg
                                                id="excel-file"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18.889"
                                                height="18.886"
                                                viewBox="0 0 18.889 18.886"
                                              >
                                                <path
                                                  id="Path_12182"
                                                  data-name="Path 12182"
                                                  d="M5.423-510.852C2.45-510.277.015-509.8.011-509.8S0-506.5,0-502.459v7.342l.081.018c.048.011,2.461.476,5.361,1.033s5.313,1.022,5.361,1.033l.081.018v-9.445c0-5.195-.011-9.445-.026-9.441S8.4-511.428,5.423-510.852Zm-.616,5.885c.3.627.579,1.236.62,1.354s.081.24.092.269.077-.1.162-.314.387-.834.694-1.4l.557-1.033.926-.011.93-.007-.074.118c-.627,1.029-2.084,3.453-2.1,3.486s1.819,3.084,2.2,3.656l.055.081-.992-.007-.989-.011-.657-1.236a13.05,13.05,0,0,1-.7-1.431c-.026-.1-.052-.185-.063-.177s-.052.125-.1.255-.369.771-.719,1.424l-.635,1.184H3.04c-.535,0-.974-.011-.974-.022s1.033-1.645,2.2-3.468l.122-.188-.978-1.675-1.07-1.834-.092-.155H4.265Z"
                                                  transform="translate(0 511.9)"
                                                  // fill="#fff"
                                                />
                                                <path
                                                  id="Path_12183"
                                                  data-name="Path 12183"
                                                  d="M315-451.912v1.088h2.214v1.476H315v1.439h2.177v1.439H315v1.476h2.177v1.439H315v1.439h2.177v1.476H315v2.177h3.376a32.513,32.513,0,0,0,3.5-.052.726.726,0,0,0,.354-.365c.03-.074.041-2.177.041-6.855,0-5.648-.007-6.77-.052-6.87a.726.726,0,0,0-.365-.354c-.07-.03-1.184-.041-3.479-.041H315Zm5.822,1.815-.011.73-1.446.011-1.45.007v-1.476h2.915Zm.007,2.907v.719h-2.915v-1.439h2.915Zm0,2.915v.719h-2.915v-1.439h2.915Zm-.007,2.9.007.73h-2.915v-1.476l1.45.007,1.446.011Z"
                                                  transform="translate(-303.379 455.173)"
                                                  // fill="#fff"
                                                />
                                              </svg>
                                            </Link>
                                          </span>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td>
                                          <span></span>
                                        </td>
                                        <td>
                                          <span></span>
                                        </td>
                                      </>
                                    )}
                                    <td>
                                      <span>
                                        {invoiceList.invoice.paymentdate}
                                      </span>
                                    </td>

                                    <td>
                                      {invoiceList.invoice.statusflag ===
                                      "4" ? (
                                        <>
                                          <span className="paid-invoice">
                                            Paid
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaidInvoiceList;
