import React, { useEffect, useState } from "react";
import Filters from "./Filters";
import "./learn.css";
import AuthService from "../../services/auth.service";
import CourseImg from "../../assets/images/course-placeholder.png";
import { Link } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import { ColorRing } from "react-loader-spinner";

import Loading from "../../assets/images/Loading.gif";

import GuestuserService from "../../services/guestuser.service";

import CourseService from "../../services/course.service";

import axios from "axios";

function GuestCourseCatalog() {
  const [courses, setCourses] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOcc, setSelectedOcc] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [listViewIcon, setListViewIcon] = useState(true);
  const [listView, setListView] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [learnerCourseIds, setLearnerCourseIds] = useState([]);

  const [filterMode, setFilterMode] = useState(false);

  const isCatalog = "Catalog";

  const [ip, setIp] = useState("");

  const [country, setCountry] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 8;

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = filteredData.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (e) => {
    const { value } = e.target;
    filterData(value);
  };

  useEffect(() => {
    fetchIP();
  }, []);

  const fetchCourseData = async () => {
    GuestuserService.getGuestAllCourses()
      .then((resp) => {
        const newData = resp.data;
        console.log(newData);
        setCourses(newData);
        setFilteredData(newData);
        setIsLoadingCourses(false);
        setFilterMode(false);
      })
      .catch((e) => console.log(e));
  };

  const filterData = (cname) => {
    if (cname !== "") {
      const filData = Array.isArray(filteredData)
        ? filteredData.filter((course) =>
            course.coursename.toLowerCase().includes(cname.toLowerCase())
          )
        : null;

      setFilteredData(filData);
      setIsLoadingCourses(false);
    } else {
      fetchCourseData();
    }
  };

  const fetchIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setIp(response.data.ip);
      getCountryFromIP(response.data.ip);
    } catch (error) {
      console.error("Error fetching the IP address:", error);
    }
  };

  const getCountryFromIP = async (ip) => {
    try {
      const response = await fetch(
        `https://geolocation-db.com/json/${ip}&position=true`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setCountry(result.country_code);
    } catch (error) {
      console.log(error);
      return null; // Return null in case of error
    }
  };

  const applyOccupCatFilter = (selectedValue) => {
    const filData = Array.isArray(filteredData)
      ? filteredData.filter((course) => course.category_name === selectedValue)
      : null;

    const data = {
      catid: "",
      occupid: selectedValue,
    };

    GuestuserService.getAllCourseFilterGuest(data)
      .then((resp) => {
        if (resp.data === false) {
          setFilterMode(true);
        } else {
          setFilteredData(resp.data);

          setIsLoadingCourses(false);
          setFilterMode(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  $("#filterCourseCat").on("change", function () {
    const selectedCatValue = $(this).val();
    setSelectedCat(selectedCatValue);
    setEmpty(false);
    applyCourseCatFilter(selectedCatValue);
  });

  $("#filterOccupCat").on("change", function () {
    const selectedValue = $(this).val();
    setSelectedOcc(selectedValue);
    setEmpty(false);
    applyOccupCatFilter(selectedValue);
  });

  $("#clearFilter").on("click", function () {
    $("#filterOccupCat").val(0);
    $("#filterCourseCat").val(0);
    setEmpty(true);
    const user = AuthService.getCurrentUser();
    if (user) {
      fetchCourseData(user);
    } else {
      fetchCourseData();
    }
  });

  const applyCourseCatFilter = (selectedCatValue) => {
    const data = {
      catid: selectedCatValue,
      occupid: "",
    };

    console.log(data);

    GuestuserService.getAllCourseFilterGuest(data).then((resp) => {
      console.log(resp.data);
      if (resp.data === false) {
        setFilterMode(true);
      } else {
        setFilteredData(resp.data);

        setIsLoadingCourses(false);
        setFilterMode(false);
      }
    });
  };

  const applyBothCatFilter = () => {
    const data = {
      catid: selectedCat,
      occupid: selectedOcc,
    };

    GuestuserService.getEstoreCourseFilterGuest(data).then((resp) => {
      setFilteredData(resp.data);
    });

    setIsLoadingCourses(false);
  };

  useEffect(() => {
    fetchCourseData();
  }, []);

  const handleListView = () => {
    setListView(true);
    setListViewIcon(false);
  };

  const handleGridView = () => {
    setListView(false);
    setListViewIcon(true);
  };

  // const [hoveredItem, setHoveredItem] = useState(null);

  // const handleMouseEnter = (id) => {
  //   setHoveredItem(id);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredItem(null);
  // };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <div className="action-flex">
                  <div className="heading">
                    <h1>Course Catalog</h1>
                  </div>
                </div>
                <div className="searchbar-view-container">
                  <div className="searchbar-view">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="15"
                        viewBox="0 0 22.58 22.583"
                      >
                        <g
                          id="Group_11449"
                          data-name="Group 11449"
                          transform="translate(-2402.326 -6200.833)"
                        >
                          <path
                            id="Path_11890"
                            data-name="Path 11890"
                            d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                            transform="translate(752.656 5510.995)"
                            fill="none"
                            //   stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                          <path
                            id="Path_11892"
                            data-name="Path 11892"
                            d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                            transform="translate(751.921 5511.583)"
                            //   fill="#fff"
                          />
                        </g>
                      </svg>
                    </span>
                    <input
                      className="searchBar"
                      type="text"
                      placeholder="Start typing to search..."
                      onChange={handleSearch}
                    />
                  </div>
                  {listViewIcon ? (
                    <span
                      className="catalog-icon"
                      onClick={handleListView}
                      title="List View"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        viewBox="0 0 24 18"
                      >
                        <g id="list" transform="translate(0 -3)">
                          <path
                            id="Path_21689"
                            data-name="Path 21689"
                            d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z"
                            fill="#fff"
                          />
                          <path
                            id="Path_21690"
                            data-name="Path 21690"
                            d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z"
                            fill="#fff"
                          />
                          <path
                            id="Path_21691"
                            data-name="Path 21691"
                            d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z"
                            fill="#fff"
                          />
                          <circle
                            id="Ellipse_157"
                            data-name="Ellipse 157"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(0 3)"
                            fill="#fff"
                          />
                          <circle
                            id="Ellipse_158"
                            data-name="Ellipse 158"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(0 10)"
                            fill="#fff"
                          />
                          <circle
                            id="Ellipse_159"
                            data-name="Ellipse 159"
                            cx="2"
                            cy="2"
                            r="2"
                            transform="translate(0 17)"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </span>
                  ) : (
                    <span
                      className="catalog-icon"
                      onClick={handleGridView}
                      title="Grid View"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="20"
                        viewBox="0 0 24.273 24.277"
                      >
                        <g id="catalog" transform="translate(-53.5 -53.438)">
                          <g
                            id="Group_11866"
                            data-name="Group 11866"
                            transform="translate(53.5 53.438)"
                          >
                            <path
                              id="Path_21664"
                              data-name="Path 21664"
                              d="M54.461,53.441q2.373-.015,4.746.03a1,1,0,0,1,.931.811q.06,2.523,0,5.047a1.17,1.17,0,0,1-.751.751q-2.523.06-5.047,0a1,1,0,0,1-.811-.931q-.06-2.373,0-4.746A1.039,1.039,0,0,1,54.461,53.441Z"
                              transform="translate(-53.5 -53.438)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.978"
                            />
                          </g>
                          <g
                            id="Group_11867"
                            data-name="Group 11867"
                            transform="translate(62.332 53.438)"
                          >
                            <path
                              id="Path_21665"
                              data-name="Path 21665"
                              d="M201.461,53.441q2.343-.015,4.686.03a1.027,1.027,0,0,1,.931.751q.06,2.584,0,5.167a.949.949,0,0,1-.751.691q-2.523.06-5.047,0a.949.949,0,0,1-.751-.691q-.06-2.584,0-5.167A1.106,1.106,0,0,1,201.461,53.441Z"
                              transform="translate(-200.5 -53.438)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.985"
                            />
                          </g>
                          <g
                            id="Group_11868"
                            data-name="Group 11868"
                            transform="translate(71.104 53.438)"
                          >
                            <path
                              id="Path_21666"
                              data-name="Path 21666"
                              d="M347.461,53.441q2.373-.015,4.746.03a.978.978,0,0,1,.931.931q.06,2.373,0,4.746a1,1,0,0,1-.811.931q-2.523.06-5.047,0a1.17,1.17,0,0,1-.751-.751q-.06-2.523,0-5.047A1.073,1.073,0,0,1,347.461,53.441Z"
                              transform="translate(-346.5 -53.438)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.978"
                            />
                          </g>
                          <g
                            id="Group_11869"
                            data-name="Group 11869"
                            transform="translate(53.5 62.27)"
                          >
                            <path
                              id="Path_21667"
                              data-name="Path 21667"
                              d="M54.281,200.441q2.584-.015,5.167.03a.949.949,0,0,1,.691.751q.06,2.523,0,5.047a.949.949,0,0,1-.691.751q-2.584.06-5.167,0a1.027,1.027,0,0,1-.751-.931q-.06-2.343,0-4.686A1.118,1.118,0,0,1,54.281,200.441Z"
                              transform="translate(-53.5 -200.437)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.985"
                            />
                          </g>
                          <g
                            id="Group_11870"
                            data-name="Group 11870"
                            transform="translate(62.332 62.27)"
                          >
                            <path
                              id="Path_21668"
                              data-name="Path 21668"
                              d="M201.221,200.441q2.584-.015,5.167.03a1,1,0,0,1,.691.691q.06,2.584,0,5.167a1,1,0,0,1-.691.691q-2.584.06-5.167,0a1,1,0,0,1-.691-.691q-.06-2.584,0-5.167A1.126,1.126,0,0,1,201.221,200.441Z"
                              transform="translate(-200.5 -200.437)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.991"
                            />
                          </g>
                          <g
                            id="Group_11871"
                            data-name="Group 11871"
                            transform="translate(71.104 62.27)"
                          >
                            <path
                              id="Path_21669"
                              data-name="Path 21669"
                              d="M347.221,200.441q2.584-.015,5.167.03a1.027,1.027,0,0,1,.751.931q.06,2.343,0,4.686a1.027,1.027,0,0,1-.751.931q-2.584.06-5.167,0a.949.949,0,0,1-.691-.751q-.06-2.523,0-5.047A1.054,1.054,0,0,1,347.221,200.441Z"
                              transform="translate(-346.5 -200.437)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.985"
                            />
                          </g>
                          <g
                            id="Group_11872"
                            data-name="Group 11872"
                            transform="translate(53.5 71.041)"
                          >
                            <path
                              id="Path_21670"
                              data-name="Path 21670"
                              d="M54.341,346.441q2.524-.015,5.047.03a1.17,1.17,0,0,1,.751.751q.06,2.523,0,5.047a1,1,0,0,1-.931.811q-2.373.06-4.746,0a.978.978,0,0,1-.931-.931q-.06-2.373,0-4.746A1.08,1.08,0,0,1,54.341,346.441Z"
                              transform="translate(-53.5 -346.437)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.978"
                            />
                          </g>
                          <g
                            id="Group_11873"
                            data-name="Group 11873"
                            transform="translate(62.332 71.041)"
                          >
                            <path
                              id="Path_21671"
                              data-name="Path 21671"
                              d="M201.281,346.441q2.524-.015,5.047.03a.949.949,0,0,1,.751.691q.06,2.584,0,5.167a1.027,1.027,0,0,1-.931.751q-2.343.06-4.686,0a1.027,1.027,0,0,1-.931-.751q-.06-2.584,0-5.167A1.049,1.049,0,0,1,201.281,346.441Z"
                              transform="translate(-200.5 -346.437)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.985"
                            />
                          </g>
                          <g
                            id="Group_11874"
                            data-name="Group 11874"
                            transform="translate(71.104 71.041)"
                          >
                            <path
                              id="Path_21672"
                              data-name="Path 21672"
                              d="M347.281,346.441q2.524-.015,5.047.03a1,1,0,0,1,.811.931q.06,2.373,0,4.746a.975.975,0,0,1-.991.931q-2.343.06-4.686,0a1,1,0,0,1-.931-.811q-.06-2.523,0-5.047A1.316,1.316,0,0,1,347.281,346.441Z"
                              transform="translate(-346.5 -346.437)"
                              // fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.978"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  )}
                </div>

                <div className="container">
                  {isLoadingCourses ? (
                    <div className="loading">
                      <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    </div>
                  ) : (
                    <>
                      {listView ? (
                        <div className="list-container">
                          {Array.isArray(filteredData) ? (
                            filteredData.map((course) => (
                              <>
                                {filteredData.length > 0 ? (
                                  <Link
                                    to={"/CourseOverview"}
                                    state={{
                                      cid: course.course_id,
                                    }}
                                    key={course.course_id}
                                  >
                                    <div
                                      className="list-item"
                                      // onMouseEnter={() =>
                                      //   handleMouseEnter(course.course_id)
                                      // }
                                      // onMouseLeave={handleMouseLeave}
                                    >
                                      <div className="grid-item-img">
                                        {course.corsimg ? (
                                          <>
                                            <img
                                              className="list-course-img"
                                              src={
                                                "https://exceltest.aeontechhub.com/lms/" +
                                                course.corsimg
                                              }
                                              loading="lazy"
                                              alt=""
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className="list-course-img"
                                              src={CourseImg}
                                              alt=""
                                            />
                                          </>
                                        )}
                                      </div>

                                      <div className="list-course-details">
                                        <div className="list-course-details2">
                                          <span className="list-course-name">
                                            {course.coursename}
                                          </span>
                                          <span className="list-course-cat">
                                            {course.course_catename
                                              ? course.course_catename
                                              : "No Category"}
                                          </span>
                                        </div>
                                        <div className="list-course-more">
                                          <div className="list-read-more">
                                            <div className="listReadMore">
                                              Read more
                                            </div>
                                            <span className="read-more-icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="10"
                                                viewBox="0 0 14 10"
                                              >
                                                <path
                                                  id="arrow-small-right_1_"
                                                  data-name="arrow-small-right (1)"
                                                  d="M18,11.005h0a1.545,1.545,0,0,0-.59-1.168L13.12,6.252a1.146,1.146,0,0,0-1.41,0,.74.74,0,0,0,0,1.184L15,10.171H5a.928.928,0,0,0-1,.834.928.928,0,0,0,1,.834H15l-3.29,2.744a.74.74,0,0,0-.005,1.18,1.146,1.146,0,0,0,1.415,0l4.29-3.586A1.544,1.544,0,0,0,18,11.005Z"
                                                  transform="translate(-4 -6.009)"
                                                  // fill="#fff"
                                                />
                                              </svg>
                                            </span>
                                          </div>

                                          <span className="list-price">
                                            {country === "BH" ? (
                                              <>{course.price} BHD</>
                                            ) : (
                                              <>
                                                {course.course_id !== "17" &&
                                                course.course_id !== "44" ? (
                                                  <>{course.price} BHD</>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                ) : (
                                  <>
                                    <div className="loading">
                                      <h1>
                                        There is no courses in E-Commerce yet.
                                      </h1>
                                      <img src={Loading} width="200px" alt="" />
                                    </div>
                                  </>
                                )}
                              </>
                            ))
                          ) : (
                            <>
                              <div className="loading">
                                <h1>
                                  There is no data available to selected filter.
                                </h1>
                                <img src={Loading} width="200px" alt="" />
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div
                          className={
                            filterMode
                              ? "grid-container gridone"
                              : "grid-container"
                          }
                        >
                          {Array.isArray(filteredData) ? (
                            filteredData.map((course) => (
                              <>
                                {filteredData.length > 0 ? (
                                  <Link
                                    to={"/CourseOverview"}
                                    state={{
                                      cid: course.course_id,
                                    }}
                                    key={course.course_id}
                                  >
                                    <div
                                      className="grid-item"
                                      // onMouseEnter={() =>
                                      //   handleMouseEnter(course.id)
                                      // }
                                      // onMouseLeave={handleMouseLeave}
                                    >
                                      <div className="grid-item-img">
                                        {course.corsimg ? (
                                          <>
                                            <img
                                              className="course-img"
                                              src={
                                                "https://exceltest.aeontechhub.com/lms/" +
                                                course.corsimg
                                              }
                                              loading="lazy"
                                              alt=""
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className="course-img"
                                              src={CourseImg}
                                              alt=""
                                            />
                                          </>
                                        )}
                                      </div>

                                      <div className="courseDetails">
                                        <div className="courseDetails2">
                                          <span className="course-name">
                                            {course.coursename}
                                          </span>
                                          <span className="course-cat">
                                            {course.course_catename
                                              ? course.course_catename
                                              : "No Category"}
                                          </span>
                                        </div>
                                        <div className="gird-course-more">
                                          <div className="grid-read-more">
                                            <div className="read-more">
                                              Read more
                                            </div>
                                            <span className="read-more-icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="10"
                                                viewBox="0 0 14 10"
                                              >
                                                <path
                                                  id="arrow-small-right_1_"
                                                  data-name="arrow-small-right (1)"
                                                  d="M18,11.005h0a1.545,1.545,0,0,0-.59-1.168L13.12,6.252a1.146,1.146,0,0,0-1.41,0,.74.74,0,0,0,0,1.184L15,10.171H5a.928.928,0,0,0-1,.834.928.928,0,0,0,1,.834H15l-3.29,2.744a.74.74,0,0,0-.005,1.18,1.146,1.146,0,0,0,1.415,0l4.29-3.586A1.544,1.544,0,0,0,18,11.005Z"
                                                  transform="translate(-4 -6.009)"
                                                  // fill="#fff"
                                                />
                                              </svg>
                                            </span>
                                          </div>

                                          <span className="list-price">
                                            {country === "BH" ? (
                                              <>{course.price} BHD</>
                                            ) : (
                                              <>
                                                {course.course_id !== "17" &&
                                                course.course_id !== "44" ? (
                                                  <>{course.price} BHD</>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                ) : (
                                  <>
                                    <div className="loading">
                                      <h1>
                                        There is no courses in E-Commerce yet.
                                      </h1>
                                      <img src={Loading} width="200px" alt="" />
                                    </div>
                                  </>
                                )}
                              </>
                            ))
                          ) : (
                            <>
                              <div className="loading">
                                <h1>
                                  There is no data available to selected filter.
                                </h1>
                                <img src={Loading} width="200px" alt="" />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* <div className="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#">
                          Previous
                        </a>
                      </li>

                      {Array.from({
                        length: Math.ceil(filteredData.length / coursesPerPage),
                      }).map((_, index) => (
                        // <button key={index} onClick={() => paginate(index + 1)}>
                        //   {index + 1}
                        // </button>

                        <li class="page-item">
                          <a
                            class="page-link"
                            href="#"
                            key={index}
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </a>
                        </li>
                      ))}

                      <li class="page-item">
                        <a class="page-link" href="#">
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GuestCourseCatalog;
