import React, { useEffect, useState, useRef } from "react";
import "./CourseManagement.css";
import { Form, FloatingLabel, Modal, Button, Alert } from "react-bootstrap";
import SettingsService from "../../../services/settings.service";
import { Stage, Layer, Text, Line, Group, Image } from "react-konva";
import certificate from "../../../assets/images/GAA-Certificate.png";
import Select from "react-select";

import UserService from "../../../services/user.service";

import EditCertificateElement from "./EditCertificateElement";

import CourseService from "../../../services/course.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../../../services/auth.service";

const Certificate = ({ prevStep, nextStep, cid }) => {
  const [uploaded, setUploaded] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [show, setShow] = useState(false);
  const [elimaid, setElimaid] = useState("");

  const [selectedTemp, setSelectedTemp] = useState("");

  const [currentUser, setCurrentUser] = useState("");

  const [courseName, setCourseName] = useState("");

  const [completionDate, setCompletionDate] = useState("");

  const [chnageBGShow, setChnageBGShow] = useState(false);
  const [presetTemp, setPresetTemp] = useState(false);
  const [uploadTemp, setUploadTemp] = useState(false);

  const [showCerConds, setShowCerConds] = useState(false);

  const emailToStudents = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const emailToInstructors = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const [restrictAccess, setRestrictAccess] = useState([]);

  // const restrictAccess = [
  //   { value: "1", label: "Entire Course" },
  //   { value: "2", label: "Last Activity" },
  //   { value: "3", label: "Activity One" },
  //   { value: "4", label: "Activity Two" },
  //   { value: "5", label: "Activity Three" },
  // ];

  const completionStatusOpts = [
    { value: "Passed", label: "Passed" },
    { value: "Completed", label: "Completed" },
  ];

  const handleChangeBGClose = () => setChnageBGShow(false);
  const handleChangeBGShow = (e) => {
    setChnageBGShow(true);
    // setElimaid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handlePresetTempClose = () => setPresetTemp(false);
  const handlePresetTempShow = (e) => {
    setPresetTemp(true);
    // setElimaid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleUploadTempClose = () => setUploadTemp(false);
  const handleUploadTempShow = (e) => {
    setUploadTemp(true);
    // setElimaid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleCerCondsClose = () => setShowCerConds(false);
  const handleCerCondsShow = (e) => {
    setShowCerConds(true);
    // setElimaid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const [elimaids, setElimaids] = useState("");

  const [editable, setEditable] = useState(true);

  const [cerImgs, setCerImgs] = useState({
    logo: "",
    qrcode: "",
    instsign: "",
  });

  // const [urlImg, setUrlImg] = useState("https://exceltest.aeontechhub.com/lms/uploads/images/aeon-soft.png");

  const [isDragging, setIsDragging] = useState(false);
  const [xAxis, setXAxis] = useState(250);
  const [yAxis, setYAxis] = useState(250);

  const [certificateEles, setCertificateEles] = useState([]);
  const [addedElements, setAddedElements] = useState([]);
  const [draggedElements, setDraggedElements] = useState({});

  const [canvasElements, setCanvasElements] = useState({});

  const [savedState, setSavedState] = useState("");

  const stageRef = useRef(null);

  const rectWidth = 100;
  const rectHeight = 100;

  const canvasRef = useRef(null);

  const [options, setOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState();

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const iconSize = 8;
  const iconSpacing = 5;

  const [selectCertEle, setSelectCertEle] = useState({
    element_id: "",
    file: "",
    fontsize: "",
    fontfamily: "",
    color: "#000000",
  });

  const [chnageBg, setChangeBg] = useState("");

  const [applyCer, setApplyCer] = useState([]);

  const [cerTemp, setCerTemp] = useState("");

  const [cerTemps, setCerTemps] = useState([]);

  const [selectedCertImage, setSelectedCertImage] = useState("");

  const [courseActivities, setCourseActivities] = useState([]);

  const getCerTemps = (uid) => {
    SettingsService.getCertTemps(uid)
      .then((resp) => setCerTemps(resp.data))
      .catch((e) => console.log(e));
  };

  const getCourseActivities = () => {
    CourseService.getCourseActivity(cid)
      .then((response) => {
        setCourseActivities(response.data);

        const restrictAccessArr = [
          {
            value: "all",
            label: "Entire Course",
          },
        ];

        if (Array.isArray(response.data)) {
          const mappedActivities = response.data.map((course) => ({
            value: course.id,
            label: course.activity_name,
          }));
          restrictAccessArr.push(...mappedActivities);
        }

        setRestrictAccess(restrictAccessArr);
      })
      .catch((e) => console.log(e));
  };

  const [cerConds, setCerConds] = useState({
    emailToStudents: "0",
    emailToInstructors: "0",
    restrictAccess: "",
    completionStatus: "",
  });

  const [showFileInput, setShowFileInput] = useState(false);

  const [fontFamilyOpts, setFontFamilyOpts] = useState([
    { label: "Courier", value: "Courier" },
    { label: "Helvetica", value: "Helvetica" },
    { label: "Calibri", value: "Calibri" },
    { label: "Times New Roman", value: "Times New Roman" },
    { label: "Roboto", value: "Roboto" },
    { label: "Open Sans", value: "Open Sans" },
  ]);

  const [selectedFontFamilyOpts, setSelectedFontFamilyOpts] = useState({
    label: "Helvetica",
    value: "Helvetica",
  });

  const handleDragBound = (pos) => {
    // Define the boundaries of the canvas
    const stage = stageRef.current;
    const canvasWidth = stage.width();
    const canvasHeight = stage.height();

    // Adjust the position to keep the element inside the canvas
    const x = Math.max(0, Math.min(pos.x, canvasWidth - rectWidth));
    const y = Math.max(0, Math.min(pos.y, canvasHeight - rectHeight));

    return { x, y };
  };

  const getCerById = () => {
    const user = AuthService.getCurrentUser();

    CourseService.getCourseById(user.user, cid)
      .then((resp) => {
        if (resp.data !== false) {
          setSelectedTemp(resp.data.course_info[0].templete);

          SettingsService.getTempByID(
            resp.data.course_info[0].templete,
            user.user
          )
            .then((respd) => {
              if (respd.data !== false) {
                setSelectedCertImage(respd.data[0].filepath);
              }
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const getCertConds = () => {
    const user = AuthService.getCurrentUser();
    const data = {
      usp: user.user,
      course_id: cid,
    };
    CourseService.getCertConds(data)
      .then((resp) => {
        if (!resp.data.error) {
          setCerConds({
            emailToStudents: resp.data[0].std_email,
            emailToInstructors: resp.data[0].ins_email,
            restrictAccess: resp.data[0].restrict_act,
            completionStatus: resp.data[0].comp_sts,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user.role_id === "4") {
      UserService.getLearners(user.user)
        .then((res) => {
          const filteredData = res.data.filter(
            (learner) => learner.user_id === user.user
          );
          setCurrentUser(
            filteredData[0].first_name + " " + filteredData[0].last_name
          );
        })
        .catch((e) => console.log(e));
    } else {
      UserService.getSystemManagers(user.user)
        .then((res) => {
          const filteredData = res.data.filter(
            (admin) => admin.user_id === user.user
          );
          setCurrentUser(
            filteredData[0].first_name + " " + filteredData[0].last_name
          );
        })
        .catch((e) => console.log(e));
    }

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const currentDate = new Date();
    const monthName = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    const formattedDate = `${monthName} ${day}, ${year}`;

    setCompletionDate(formattedDate);

    CourseService.getCourseById(user.user, cid)
      .then((res) => {
        setCourseName(res.data.course_info[0].name);
      })
      .catch((e) => console.log(e));

    SettingsService.getCertificateElements(user.user)
      .then((response) => {
        // setCertificateEles(response.data);
        setOptions(
          response.data.map((cert) => ({
            value: cert.id,
            label: cert.elementname,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });

    getCerTemps(user.user);

    getCerById();

    getCourseActivities();

    getCertConds();

    CourseService.getCourseCerEle(cid)
      .then((response) => {
        if (response.data !== false) {
          setCertificateEles(response.data);
          const allEle = response.data;
          const filteredData = response.data.filter((ele) => ele.apl === "1");
          setAddedElements(filteredData);

          let logoimg = allEle.filter((logo) => logo.element_id === "5");

          let qrimg = allEle.filter((logo) => logo.element_id === "6");

          let instsign = allEle.filter((logo) => logo.element_id === "7");

          if (logoimg.length > 0 && qrimg.length > 0 && instsign.length > 0) {
            setCerImgs({
              logo: `https://exceltest.aeontechhub.com/lms/${logoimg[0].file}`,
              qrcode: `https://exceltest.aeontechhub.com/lms/${qrimg[0].file}`,
              instsign: `https://exceltest.aeontechhub.com/lms/${instsign[0].file}`,
            });
          } else if (logoimg.length > 0 && qrimg.length > 0) {
            setCerImgs({
              logo: `https://exceltest.aeontechhub.com/lms/${logoimg[0].file}`,
              qrcode: `https://exceltest.aeontechhub.com/lms/${qrimg[0].file}`,
              instsign: "",
            });
          } else if (logoimg.length > 0 && instsign.length > 0) {
            setCerImgs({
              logo: `https://exceltest.aeontechhub.com/lms/${logoimg[0].file}`,
              qrcode: "",
              instsign: `https://exceltest.aeontechhub.com/lms/${instsign[0].file}`,
            });
          } else if (qrimg.length > 0 && instsign.length > 0) {
            setCerImgs({
              logo: "",
              qrcode: `https://exceltest.aeontechhub.com/lms/${qrimg[0].file}`,
              instsign: `https://exceltest.aeontechhub.com/lms/${instsign[0].file}`,
            });
          } else if (logoimg.length > 0) {
            setCerImgs({
              ...cerImgs,
              logo: `https://exceltest.aeontechhub.com/lms/${logoimg[0].file}`,
            });
          } else if (qrimg.length > 0) {
            setCerImgs({
              ...cerImgs,
              qrcode: `https://exceltest.aeontechhub.com/lms/${qrimg[0].file}`,
            });
          } else if (instsign.length > 0) {
            setCerImgs({
              ...cerImgs,
              instsign: `https://exceltest.aeontechhub.com/lms/${instsign[0].file}`,
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const [validated, setValidated] = useState(false);

  const certificateImage = new window.Image();

  const certlogo = new window.Image();

  const certsign = new window.Image();

  const certqrcode = new window.Image();

  if (selectedCertImage) {
    certificateImage.src = `https://exceltest.aeontechhub.com/lms/${selectedCertImage}`;
  }

  certlogo.src = cerImgs.logo;

  certsign.src = cerImgs.instsign;

  certqrcode.src = cerImgs.qrcode;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectCertEle({ ...selectCertEle, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setSelectCertEle({ ...selectCertEle, [name]: e.target.files[0] });
    setUploaded(true);
  };

  const handleChangeBG = (e) => {
    // setSelectCertEle({ ...selectCertEle, [name]: e.target.files[0] });
    setChangeBg(e.target.files[0]);
    setUploaded(true);
  };

  const handleUploadTemp = (e) => {
    // setSelectCertEle({ ...selectCertEle, [name]: e.target.files[0] });
    setCerTemp(e.target.files[0]);
    setUploaded(true);
  };

  // const handleCertificateConditions = (e) => {
  //   // setSelectCertEle({ ...selectCertEle, [name]: e.target.files[0] });
  //   // setCerConds(e.target.files[0])
  //   setUploaded(true);
  // }

  const handleSelectChange = (event, name) => {
    const value = event ? event.value : "";

    setCerConds({ ...cerConds, [name]: value });
  };

  const handleSelectOptionChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
    const value = selectedOption ? selectedOption.value : "";
    setSelectCertEle({ ...selectCertEle, element_id: value });

    if (value == "5" || value == "6" || value == "7") {
      setShowFileInput(true);
    } else {
      setShowFileInput(false);
    }
  };

  const handleFontFamilyChange = (selectedFontFamilyOpts) => {
    setSelectedFontFamilyOpts(selectedFontFamilyOpts);
    const value = selectedFontFamilyOpts ? selectedFontFamilyOpts.value : "";
    setSelectCertEle({ ...selectCertEle, fontfamily: value });
  };

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setElimaid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const prevPage = (e) => {
    e.preventDefault();
    prevStep();
  };

  const onDrag = (event, cele) => {
    event.preventDefault();
    setDraggedElements(cele);
    setElimaids(event.currentTarget.getAttribute("data-bs-target"));
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    setAddedElements([...addedElements, draggedElements]);
    setCertificateEles(
      certificateEles.filter((task) => task.id !== draggedElements.id)
    );
    setDraggedElements();
  };

  const arrangeElements = (e) => {
    if (e && e.target) {
      setIsDragging(false);
      setXAxis(e.target.x());
      setYAxis(e.target.y());
    }
  };

  const handleSaveState = () => {
    // e.preventDefault();
    // Save the current canvas state
    const snapshot = [...addedElements];

    if (snapshot.length > 0) {
      setSavedState(snapshot);
      setEditable(false);

      toast.success("Certificate Configured Successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.warn("Drag and Drop Certificate Elements!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const user = AuthService.getCurrentUser();

    CourseService.applyCerEle(user.user, snapshot)
      .then((response) => {})
      .catch((e) => console.log(e));
  };

  const removeElement = (indexToRemove) => {
    // const updatedElements = addedElements.filter((_, index) => index !== indexToRemove);
    const updatedElements = addedElements.filter(
      (addedElement) => addedElement.id !== indexToRemove
    );
    const user = AuthService.getCurrentUser();

    CourseService.getCourseCerEle(cid)
      .then((response) => {
        setCertificateEles(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setAddedElements(updatedElements);

    CourseService.removeElement(user.user, indexToRemove)
      .then((response) => {})
      .catch((e) => console.log(e));
  };

  const addCourseCerEle = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);

      toast.warn("Please fill all required fields!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (uploaded) {
      const user = AuthService.getCurrentUser();

      const formData = new FormData();

      formData.append("element_id", selectCertEle.element_id);
      formData.append("logo", selectCertEle.file);
      formData.append("fontsize", selectCertEle.fontsize);
      formData.append("fontfamily", selectCertEle.fontfamily);
      formData.append("color", selectCertEle.color);
      formData.append("corsid", cid);
      formData.append("usp", user.user);

      const foundObject = addedElements.find(
        (obj) => obj.element_id === selectCertEle.element_id
      );

      if (foundObject && foundObject.element_id === selectCertEle.element_id) {
        toast.warn("Certificate Element Already Added!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        CourseService.addCourseCerEle(formData)
          .then((response) => {
            toast.success("Certificate Element Added Successfully!", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setSelectCertEle({
              element_id: "",
              file: "",
              fontsize: "",
              fontfamily: "",
              color: "#000000",
            });
            CourseService.getCourseCerEle(cid)
              .then((response) => {
                setCertificateEles(response.data);
                setAddedElements(
                  response.data.filter((ele) => ele.apl === "1")
                );
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      const user = AuthService.getCurrentUser();

      const formData = new FormData();

      formData.append("element_id", selectCertEle.element_id);
      formData.append("fontsize", selectCertEle.fontsize);
      formData.append("fontfamily", selectCertEle.fontfamily);
      formData.append("color", selectCertEle.color);
      formData.append("corsid", cid);
      formData.append("usp", user.user);

      const foundObject = addedElements.find(
        (obj) => obj.element_id === selectCertEle.element_id
      );

      if (foundObject && foundObject.element_id === selectCertEle.element_id) {
        toast.warn("Certificate Element Already Added!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        CourseService.addCourseCerEle(formData)
          .then((response) => {
            toast.success("Certificate Element Added Successfully!", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setSelectCertEle({
              element_id: "",
              file: "",
              fontsize: "",
              fontfamily: "",
              color: "#000000",
            });
            CourseService.getCourseCerEle(cid)
              .then((response) => {
                setCertificateEles(response.data);
                setAddedElements(
                  response.data.filter((ele) => ele.apl === "1")
                );
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    if (addedElements.length > 0) {
      nextStep();
    } else {
      toast.warn("You have not added any elements in certificate!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const stageWidth = 900; // Width of the stage (adjust as needed)
  const stageHeight = 625; // Height of the stage (adjust as needed)

  // const imageX = (stageWidth - 1125) / 2; // Horizontal centering
  // const imageY = (stageHeight - 795) / 2; // Vertical centering

  const imageX = 15; // Horizontal centering
  const imageY = 15; // Vertical centering

  const selectTemp = (e, tempid) => {
    e.preventDefault();
    setSelectedTemp(tempid);
  };

  const chnageCerBg = () => {
    const formData = new FormData();

    formData.append("cerbg", chnageBg);
    formData.append("courseid", cid);
    // formData.append("templateid", tempid);

    setChnageBGShow(false);
  };

  const uploadTemplate = () => {
    const formData = new FormData();

    const user = AuthService.getCurrentUser();

    formData.append("certemp", cerTemp);
    formData.append("usp", user.user);

    SettingsService.uploadCertTemps(formData)
      .then((resp) => {
        toast.success("Certificate Template Uploaded Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        getCerTemps(user.user);

        setUploadTemp(false);
      })
      .catch((e) => console.log(e));
  };

  const selectCerTemp = () => {
    const user = AuthService.getCurrentUser();

    const data = {
      usp: user.user,
      corsid: cid,
      tempid: selectedTemp,
    };

    CourseService.uploadCourseCerTemp(data)
      .then((resp) => {
        toast.success("Certificate Template Selected Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        getCerById();

        setPresetTemp(false);
      })
      .catch((e) => console.log(e));
  };

  const addCertConds = () => {
    const user = AuthService.getCurrentUser();
    const data = {
      usp: user.user,
      course_id: cid,
      std_email: cerConds.emailToStudents,
      ins_email: cerConds.emailToInstructors,
      restrict_act: cerConds.restrictAccess,
      comp_sts: cerConds.completionStatus,
    };

    CourseService.addCertConds(data)
      .then((resp) => {
        if (resp.data) {
          toast.success("Certificate Conditions Added Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setShowCerConds(false);
          getCertConds();
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="certificate-area">
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-12">
            <div className="select-cerele">
              <ToastContainer />
              <Form noValidate validated={validated} onSubmit={addCourseCerEle}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Certificate Elements</Form.Label>
                  <div className="input-box">
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: "none",
                          outline: "none",
                          borderRadius: "10px",
                          backgroundColor: state.isFocused
                            ? "var(--dark-bg)"
                            : "var(--dark-bg)",
                          height: "45px",
                        }),
                        option: (baseStyles, { isFocused, isSelected }) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? "var(--primary-color)"
                            : isFocused
                            ? "var(--secondary-color)"
                            : "var(--dark-bg)",
                          color: isSelected
                            ? "var(--dark-bg)"
                            : isFocused
                            ? "var(--dark-bg)"
                            : "var(--whiteColor)",
                        }),
                      }}
                      // className="react-select-container"
                      // classNamePrefix="react-select"
                      onChange={handleSelectOptionChange}
                      value={selectedOptions}
                      options={options}
                      issearchable="true"
                      placeholder="Select Course Category"
                      required
                    />
                  </div>
                </Form.Group>
                {showFileInput ? (
                  <>
                    <Form.Group className="" controlId="formBasicName">
                      <Form.Label>Upload File</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="file"
                          name="file"
                          onChange={handleFileChange}
                        />
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20.199 20.201"
                          >
                            <g
                              id="Group_11227"
                              data-name="Group 11227"
                              transform="translate(-2402.326 -6200.833)"
                            >
                              <path
                                id="Path_11890"
                                data-name="Path 11890"
                                d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                transform="translate(752.656 5510.435)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                id="Path_11892"
                                data-name="Path 11892"
                                d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                transform="translate(751.221 5511.583)"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group className="" controlId="formBasicName">
                      <Form.Label>Font Size *</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="number"
                          min={0}
                          placeholder="Enter Font Size"
                          name="fontsize"
                          value={selectCertEle.fontsize}
                          onChange={handleInputChange}
                          required
                        />
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20.199 20.201"
                          >
                            <g
                              id="Group_11227"
                              data-name="Group 11227"
                              transform="translate(-2402.326 -6200.833)"
                            >
                              <path
                                id="Path_11890"
                                data-name="Path 11890"
                                d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                transform="translate(752.656 5510.435)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                id="Path_11892"
                                data-name="Path 11892"
                                d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                transform="translate(751.221 5511.583)"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group className="" controlId="formBasicName">
                      <Form.Label>Font Family *</Form.Label>
                      <div className="input-box">
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              border: "none",
                              outline: "none",
                              borderRadius: "10px",
                              backgroundColor: state.isFocused
                                ? "var(--dark-bg)"
                                : "var(--dark-bg)",
                              height: "45px",
                            }),
                            option: (
                              baseStyles,
                              { isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? "var(--primary-color)"
                                : isFocused
                                ? "var(--secondary-color)"
                                : "var(--body-dark-bg)",
                              color: isSelected
                                ? "var(--body-dark-bg)"
                                : isFocused
                                ? "var(--body-dark-bg)"
                                : "var(--whiteColor)",
                            }),
                          }}
                          // className="react-select-container"
                          // classNamePrefix="react-select"
                          onChange={handleFontFamilyChange}
                          value={selectedFontFamilyOpts}
                          options={fontFamilyOpts}
                          issearchable="true"
                          placeholder="Select Font Family"
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="font-color"
                      controlId="formBasicName"
                    >
                      <Form.Label>Font Color *</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="color"
                          placeholder="Enter Font Color"
                          name="color"
                          value={selectCertEle.color}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </Form.Group>
                  </>
                )}
                <div className="elements-flex action-btn add-elemnet-btn">
                  <input type="submit" className="item" value="Submit" />
                </div>
              </Form>
            </div>
            <div className="certificate-elements">
              <div className="mb-3 mb-4">
                <label htmlFor="">Certificate Components:</label>
                <label htmlFor="">
                  Drag and Drop onto desired locations on certificate
                </label>
              </div>
              <div className="addedElements">
                <div className="elements-flex elements-text elements-upload">
                  {Array.isArray(certificateEles)
                    ? certificateEles.map((certificateEle, index) =>
                        certificateEle.apl == "0" ? (
                          certificateEle.file !== "" ? (
                            <div
                              className="img-item"
                              onClick={handleShow}
                              data-bs-target={certificateEle.id}
                              key={certificateEle.id}
                              draggable
                              onDrag={(event) => onDrag(event, certificateEle)}
                            >
                              <img
                                src={
                                  "https://exceltest.aeontechhub.com/lms/" +
                                  certificateEle.file
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          ) : (
                            <div
                              className="item"
                              onClick={handleShow}
                              data-bs-target={certificateEle.id}
                              key={certificateEle.id}
                              draggable
                              onDrag={(event) => onDrag(event, certificateEle)}
                            >
                              <span
                                style={{
                                  fontFamily: `${certificateEle.font_family} !important`,
                                  fontSize: certificateEle.fontSize,
                                }}
                              >
                                {certificateEle.elementname}
                              </span>
                            </div>
                          )
                        ) : (
                          <></>
                        )
                      )
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-12">
            <div
              className="certificate-preview mb-3"
              onDrop={(event) => onDrop(event)}
              onDragOver={(event) => onDragOver(event)}
            >
              <Stage width={stageWidth} height={stageHeight} ref={stageRef}>
                <Layer>
                  <Image
                    x={imageX}
                    y={imageY}
                    image={certificateImage}
                    width={841}
                    height={530}
                  />
                </Layer>
                <Layer>
                  {addedElements.map((addedElement, index) => (
                    <Group
                      key={index}
                      x={parseInt(addedElement.xAxis)}
                      y={parseInt(addedElement.yAxis)}
                      draggable
                      onDragStart={() => {
                        setIsDragging(true);
                        const updatedElements = [...addedElements];
                        updatedElements[index].isDragging = true;
                        setAddedElements(updatedElements);
                      }}
                      onDragEnd={(e) => {
                        setIsDragging(false);
                        const updatedElements = [...addedElements];
                        updatedElements[index].isDragging = false;
                        updatedElements[index].xAxis = e.target.x();
                        updatedElements[index].yAxis = e.target.y();
                        setAddedElements(updatedElements);
                        arrangeElements(e);
                      }}
                      dragBoundFunc={handleDragBound}
                    >
                      {addedElement.element_id === "1" ? (
                        <>
                          <Text
                            className="item"
                            text={currentUser}
                            fill={addedElement.color}
                            fontFamily={addedElement.font_family}
                            fontSize={addedElement.font_size}
                            fontStyle="Bold"
                            padding={12}
                          />
                        </>
                      ) : addedElement.element_id === "2" ? (
                        <>
                          <Text
                            className="item"
                            text={courseName}
                            fill={addedElement.color}
                            fontFamily={addedElement.font_family}
                            fontSize={addedElement.font_size}
                            fontStyle="Bold"
                            padding={12}
                            style={{ textAlign: "center" }}
                          />
                        </>
                      ) : addedElement.element_id === "11" ? (
                        <>
                          <Text
                            className="item"
                            text={completionDate}
                            fill={addedElement.color}
                            fontFamily={addedElement.font_family}
                            fontSize={addedElement.font_size}
                            padding={12}
                          />
                        </>
                      ) : addedElement.element_id === "10" ? (
                        <>
                          <Text
                            className="item"
                            text="100%"
                            fill={addedElement.color}
                            fontFamily={addedElement.font_family}
                            fontSize={addedElement.font_size}
                            padding={12}
                          />
                        </>
                      ) : addedElement.element_id === "4" ? (
                        <>
                          <Text
                            className="item"
                            text="KwiRJtfqj5"
                            fill={addedElement.color}
                            fontFamily={addedElement.font_family}
                            fontSize={addedElement.font_size}
                            padding={12}
                          />
                        </>
                      ) : addedElement.element_id === "7" ? (
                        <>
                          <Image image={certsign} width={100} height={62} />
                        </>
                      ) : addedElement.element_id === "6" ? (
                        <>
                          <Image image={certqrcode} width={75} height={75} />
                        </>
                      ) : addedElement.element_id === "5" ? (
                        <>
                          <Image image={certlogo} width={150} height={115} />
                        </>
                      ) : (
                        <></>
                      )}

                      {editable ? (
                        <>
                          <Group
                            x={
                              addedElement.elementname.length * 10 + iconSpacing
                            }
                            y={addedElement.elementname.length * 0.5}
                            onClick={() => {
                              removeElement(addedElement.id);
                            }}
                          >
                            <Line
                              points={[0, 0, iconSize, iconSize]} // Diagonal line from top-left to bottom-right
                              stroke="black"
                              strokeWidth={2}
                            />
                            <Line
                              points={[0, iconSize, iconSize, 0]} // Diagonal line from bottom-left to top-right
                              stroke="black"
                              strokeWidth={2}
                            />
                          </Group>
                        </>
                      ) : (
                        <></>
                      )}
                    </Group>
                  ))}
                </Layer>
              </Stage>
            </div>
            <div className="elements-flex action-btn">
              <button className="item" onClick={handlePresetTempShow}>
                <span>Use Preset Templates</span>
              </button>
              <button className="item" onClick={handleUploadTempShow}>
                <span>Upload Templates</span>
              </button>
              <button className="item" onClick={handleCerCondsShow}>
                <span>Certificate Conditions</span>
              </button>
              {/* <button className="item applybtn" ><span></span></button> */}
              <input
                type="submit"
                className="item"
                onClick={handleSaveState}
                value="Reposition Elements"
              ></input>
            </div>
          </div>
        </div>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="flex-row">
          <input
            type="submit"
            className="onboard-btn-o"
            value="Back"
            onClick={prevPage}
          />
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Edit Certificate Element</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <EditCertificateElement
            elimaid={elimaid}
            setShow={setShow}
            setDataUpload={setDataUpload}
            setMessage={setMessage}
            setCertificateEles={setCertificateEles}
            setAddedElements={setAddedElements}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={presetTemp}
        onHide={handlePresetTempClose}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Select Preset Templates</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handlePresetTempClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="templates-list">
            {Array.isArray(cerTemps)
              ? cerTemps.map((cerTemp) => {
                  return (
                    <div
                      key={cerTemp.id}
                      className={
                        selectedTemp === cerTemp.id
                          ? "template-item active"
                          : "template-item"
                      }
                      onClick={(e) => selectTemp(e, cerTemp.id)}
                    >
                      <img
                        src={
                          "https://exceltest.aeontechhub.com/lms/" + cerTemp.filepath
                        }
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handlePresetTempClose}>
            No
          </button>
          <button className="yes" onClick={selectCerTemp}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={uploadTemp} onHide={handleUploadTempClose} centered>
        <Modal.Header>
          <Modal.Title>Upload Preset Templates</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleUploadTempClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form className="select-cerele">
            <Form.Group className="" controlId="formBasicName">
              <Form.Label>Upload File</Form.Label>
              <div className="input-box">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={handleUploadTemp}
                />
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 20.199 20.201"
                  >
                    <g
                      id="Group_11227"
                      data-name="Group 11227"
                      transform="translate(-2402.326 -6200.833)"
                    >
                      <path
                        id="Path_11890"
                        data-name="Path 11890"
                        d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                        transform="translate(752.656 5510.435)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_11892"
                        data-name="Path 11892"
                        d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                        transform="translate(751.221 5511.583)"
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleUploadTempClose}>
            No
          </button>
          <button className="yes" onClick={uploadTemplate}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCerConds} onHide={handleCerCondsClose} centered>
        <Modal.Header>
          <Modal.Title>Add Certificate Conditions</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleCerCondsClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form className="select-cerele">
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Email To Students</Form.Label>
              <div className="input-box">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      outline: "none",
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: state.isFocused
                        ? "var(--body-dark-bg)"
                        : "var(--body-dark-bg)",
                      height: "45px",
                    }),
                    option: (baseStyles, { isFocused, isSelected }) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? "var(--primary-color)"
                        : isFocused
                        ? "var(--secondary-color)"
                        : "var(--body-dark-bg)",
                      color: isSelected
                        ? "var(--body-dark-bg)"
                        : isFocused
                        ? "var(--body-dark-bg)"
                        : "var(--whiteColor)",
                    }),
                  }}
                  options={emailToStudents}
                  issearchable="true"
                  placeholder="Select"
                  name="emailToStudents"
                  onChange={(e) => handleSelectChange(e, "emailToStudents")}
                  // value={cerConds.emailToStudents}
                  value={
                    Array.isArray(emailToStudents)
                      ? emailToStudents.filter(
                          (option) => cerConds.emailToStudents === option.value
                        )
                      : []
                  }
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Email To Instructors</Form.Label>
              <div className="input-box">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      outline: "none",
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: state.isFocused
                        ? "var(--body-dark-bg)"
                        : "var(--body-dark-bg)",
                      height: "45px",
                    }),
                    option: (baseStyles, { isFocused, isSelected }) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? "var(--primary-color)"
                        : isFocused
                        ? "var(--secondary-color)"
                        : "var(--body-dark-bg)",
                      color: isSelected
                        ? "var(--body-dark-bg)"
                        : isFocused
                        ? "var(--body-dark-bg)"
                        : "var(--whiteColor)",
                    }),
                  }}
                  options={emailToInstructors}
                  issearchable="true"
                  placeholder="Select"
                  name="emailToInstructors"
                  onChange={(e) => handleSelectChange(e, "emailToInstructors")}
                  // value={cerConds.emailToInstructors}
                  value={
                    Array.isArray(emailToInstructors)
                      ? emailToInstructors.filter(
                          (option) =>
                            cerConds.emailToInstructors === option.value
                        )
                      : []
                  }
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Restrict Access</Form.Label>
              <div className="input-box">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      outline: "none",
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: state.isFocused
                        ? "var(--body-dark-bg)"
                        : "var(--body-dark-bg)",
                      height: "45px",
                    }),
                    option: (baseStyles, { isFocused, isSelected }) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? "var(--primary-color)"
                        : isFocused
                        ? "var(--secondary-color)"
                        : "var(--body-dark-bg)",
                      color: isSelected
                        ? "var(--body-dark-bg)"
                        : isFocused
                        ? "var(--body-dark-bg)"
                        : "var(--whiteColor)",
                    }),
                  }}
                  options={restrictAccess}
                  issearchable="true"
                  placeholder="Select"
                  name="restrictAccess"
                  onChange={(e) => handleSelectChange(e, "restrictAccess")}
                  // value={cerConds.restrictAccess}

                  value={
                    Array.isArray(restrictAccess)
                      ? restrictAccess.filter(
                          (option) => cerConds.restrictAccess === option.value
                        )
                      : []
                  }
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Completion Status</Form.Label>
              <div className="input-box">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      outline: "none",
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: state.isFocused
                        ? "var(--body-dark-bg)"
                        : "var(--body-dark-bg)",
                      height: "45px",
                    }),
                    option: (baseStyles, { isFocused, isSelected }) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? "var(--primary-color)"
                        : isFocused
                        ? "var(--secondary-color)"
                        : "var(--body-dark-bg)",
                      color: isSelected
                        ? "var(--body-dark-bg)"
                        : isFocused
                        ? "var(--body-dark-bg)"
                        : "var(--whiteColor)",
                    }),
                  }}
                  options={completionStatusOpts}
                  issearchable="true"
                  placeholder="Select"
                  name="completionStatus"
                  onChange={(e) => handleSelectChange(e, "completionStatus")}
                  // value={cerConds.completionStatus}

                  value={
                    Array.isArray(completionStatusOpts)
                      ? completionStatusOpts.filter(
                          (option) => cerConds.completionStatus === option.value
                        )
                      : []
                  }
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleCerCondsClose}>
            No
          </button>
          <button className="yes" onClick={addCertConds}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Certificate;
