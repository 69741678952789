import React from "react";
import { render } from "react-dom";
import jsPDF from "jspdf";

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  view: {
    position: "relative",
    flex: 1,
  },
  certificate: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "auto",
  },
  logo: {
    position: "absolute",
    top: "25px",
    width: "100px",
    height: "100px",
    margin: "0 auto",
    marginBottom: "25px",
  },
  textone: {
    position: "absolute",
    top: "225px",
    left: "40%",
    transform: "translateX(-50%)",
    fontSize: "24px",
    textAlign: "center",
  },
  texttwo: {
    position: "absolute",
    top: "275px",
    left: "40%",
    transform: "translateX(-50%)",
    fontSize: "24px",
    textAlign: "center",
  },
});

const CertificatePreview = ({
  cerImgSrc,
  addedElements,
  currentUser,
  courseName,
  formattedDate,
  courseShortName,
  catid,
  cid,
  code,
  grade,
}) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={styles.view}>
        <Image src={cerImgSrc} style={styles.certificate} />
        {Array.isArray(addedElements)
          ? addedElements.map((addedElement) => {
              return (
                <>
                  {catid !== "9" ? (
                    <>
                      {addedElement.element_id === "1" ? (
                        <>
                          <Text
                            style={{
                              position: "relative",
                              // left: `${parseInt(addedElement.xAxis)}px`,
                              top: `${parseInt(addedElement.yAxis)}px`,
                              textAlign: "center",
                              fontFamily: `${addedElement.font_family}`,
                              fontSize: `${addedElement.font_size}`,
                              color: `${addedElement.color}`,
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser}
                          </Text>
                        </>
                      ) : addedElement.element_id === "2" ? (
                        <>
                          <Text
                            style={{
                              position: "relative",
                              // left: `${parseInt(addedElement.xAxis)}px`,
                              top: `${parseInt(addedElement.yAxis)}px`,
                              textAlign: "center",
                              fontFamily: `${addedElement.font_family}`,
                              fontSize: `${addedElement.font_size}`,
                              color: `${addedElement.color}`,
                              textTransform: "capitalize",
                            }}
                          >
                            {courseName}
                          </Text>
                        </>
                      ) : addedElement.element_id === "11" ? (
                        <>
                          <Text
                            style={{
                              position: "relative",
                              // left: `${parseInt(addedElement.xAxis)}px`,
                              top: `${parseInt(addedElement.yAxis)}px`,
                              textAlign: "center",
                              fontFamily: `${addedElement.font_family}`,
                              fontSize: `${addedElement.font_size}`,
                              color: `${addedElement.color}`,
                            }}
                          >
                            {formattedDate ? formattedDate : null}
                          </Text>
                        </>
                      ) : addedElement.element_id === "4" ? (
                        <>
                          <Text
                            style={{
                              position: "relative",
                              left: `${parseInt(addedElement.xAxis)}px`,
                              top: `${parseInt(addedElement.yAxis)}px`,
                              // textAlign: "left",
                              fontFamily: `${addedElement.font_family}`,
                              fontSize: `${addedElement.font_size}`,
                              color: `${addedElement.color}`,
                            }}
                          >
                            GAA/{courseShortName}/{code}
                          </Text>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {addedElement.element_id === "1" ? (
                        <>
                          <Text
                            style={{
                              position: "relative",
                              // left: `${parseInt(addedElement.xAxis)}px`,
                              top: `${parseInt(addedElement.yAxis)}px`,
                              textAlign: "center",
                              fontFamily: `${addedElement.font_family}`,
                              fontSize: `${addedElement.font_size}`,
                              color: `${addedElement.color}`,
                              textTransform: "capitalize",
                            }}
                          >
                            {currentUser}
                          </Text>
                        </>
                      ) : addedElement.element_id === "11" ? (
                        <>
                          <Text
                            style={{
                              position: "relative",
                              left: `${parseInt(addedElement.xAxis)}px`,
                              top: `${parseInt(addedElement.yAxis)}px`,
                              // textAlign: "center",
                              fontFamily: `${addedElement.font_family}`,
                              fontSize: `${addedElement.font_size}`,
                              color: `${addedElement.color}`,
                            }}
                          >
                            {formattedDate ? formattedDate : null}
                          </Text>
                        </>
                      ) : addedElement.element_id === "10" ? (
                        <>
                          <Text
                            style={{
                              position: "relative",
                              left: `${parseInt(addedElement.xAxis)}px`,
                              top: `${parseInt(addedElement.yAxis)}px`,
                              // textAlign: "center",
                              fontFamily: `${addedElement.font_family}`,
                              fontSize: `${addedElement.font_size}`,
                              color: `${addedElement.color}`,
                            }}
                          >
                            {grade}%
                          </Text>
                        </>
                      ) : addedElement.element_id === "4" ? (
                        <>
                          <Text
                            style={{
                              position: "relative",
                              left: `${parseInt(addedElement.xAxis)}px`,
                              top: `${parseInt(addedElement.yAxis)}px`,
                              // textAlign: "left",
                              fontFamily: `${addedElement.font_family}`,
                              fontSize: `${addedElement.font_size}`,
                              color: `${addedElement.color}`,
                            }}
                          >
                            GAA/{courseShortName}/{code}
                          </Text>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              );
            })
          : null}
      </View>
    </Page>
  </Document>
);

export default CertificatePreview;
