import React from "react";
import { Link } from "react-router-dom";
import "./learn.css";
import "./track.css";
import CourseImg from "../../assets/images/course-placeholder.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import CourseService from "../../services/course.service";
import AuthService from "../../services/auth.service";
import { useEffect } from "react";
import { useState } from "react";
import RecommendedCourses from "../../common/RecommendedCourses";
import Conversation from "../../common/Conversation";
import ChatBot from "../../common/ChatBot";
import Achievements from "./Achievements";
import Loading from "../../assets/images/Loading.gif";

const Track = () => {
  const [courses, setCourses] = useState([]);
  const [chat, setChat] = useState(false);

  const showChat = () => {
    setChat(true);
  };
  const hideChat = () => {
    setChat(false);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      getLearnersCoursesData(user.user);
    }
  }, []);

  const getLearnersCoursesData = (uid) => {
    // CourseService.getAllLearnerscourses(uid)
    //   .then((resp) => {
    //     const filterdata = resp.data.filter((item) => item.compsts === "1");
    //     setCourses(filterdata);
    //   })
    //   .catch((e) => console.log(e));

    CourseService.getLearnersCoursesWithoutPath(uid)
      .then((resp) => {
        const notfilterdata = resp.data;
        if (notfilterdata !== false) {
          const filterdata = notfilterdata.filter(
            (item) => item.compsts === "1"
          );

          setCourses(filterdata);
          // setIsLoadingCourses(false);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="container-fluid tracking">
        <div className="row">
          <div className="col-xl-9 col-lg-8">
            <div className="outer-box learn track mb-3">
              <div className="cflex">
                <div className="heading">
                  <h1>Track Compeleted Courses</h1>
                </div>
              </div>
              <div className="course-tracking">
                {Array.isArray(courses) ? (
                  courses.map((course) => (
                    <div key={course.corsid}>
                      {course.compsts === "1" ? (
                        <>
                          <div className="inner-box course">
                            <div className="course-lists course">
                              <div className="course-item">
                                <div className="course-info">
                                  <div className="course-img">
                                    {course.cimg ? (
                                      <>
                                        <img
                                          src={
                                            "https://exceltest.aeontechhub.com/lms/" +
                                            course.cimg
                                          }
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img src={CourseImg} alt="" />
                                      </>
                                    )}
                                  </div>
                                  <div className="cinf-flex">
                                    <div className="cinfo">
                                      <span className="cname">
                                        {course.coursename}
                                      </span>

                                      <span className="catname">
                                        {course.course_catename}
                                      </span>
                                    </div>
                                    <div className="range">
                                      <ProgressBar
                                        now={
                                          course.compsts === "1"
                                            ? "100"
                                            : `${course.compercent}`
                                        }
                                      />
                                    </div>
                                    <div className="course-act">
                                      <button>
                                        <Link
                                          to={"/Course"}
                                          state={{
                                            enrollid: course.enrollid,
                                            cid: course.corsid,
                                          }}
                                        >
                                          {course.compercent > 0 &&
                                          course.compercent < 100 ? (
                                            <>Finish Now</>
                                          ) : course.compsts === "1" ? (
                                            <>View Course</>
                                          ) : (
                                            <>Start Now</>
                                          )}
                                        </Link>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    <div className="loading">
                      <h1>
                        You have not completed any of your assigned courses.
                        Please complete the course to see the tracking.
                      </h1>
                      <img src={Loading} width="200px" alt="" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="outer-box recomd-courses">
              <div className="cflex">
                <div className="heading">
                  <h1>Recommended Courses</h1>
                </div>
              </div>
              <RecommendedCourses />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4">
            <div className="flex-right">
              <Achievements />
              <div className="outer-box chat">
                <div className="cflex">
                  <div className="heading">
                    <h1>Conversation</h1>
                  </div>
                </div>
                <Conversation showChat={showChat} />
              </div>
              <ChatBot chat={chat} hideChat={hideChat} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Track;
